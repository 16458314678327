/*
@font-face {
  font-family: 'BankGothic';
  src: url('/assets/fonts/bankgothic-lt-bt-light-webfont.eot');
  src: url('/assets/fonts/bankgothic-lt-bt-light-webfont.eot?#iefix') format('eot'),
       url('/assets/fonts/bankgothic-lt-bt-light-webfont.woff') format('woff'),
       url('/assets/fonts/bankgothic-lt-bt-light-webfont.ttf') format('truetype'),
       url('/assets/fonts/bankgothic-lt-bt-light-webfont.svg#webfontqkeXzljY') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BankGothic';
  src: url('/assets/fonts/bankgothic-md-bt-medium-webfont.eot');
  src: url('/assets/fonts/bankgothic-md-bt-medium-webfont.eot?#iefix') format('eot'),
       url('/assets/fonts/bankgothic-md-bt-medium-webfont.woff') format('woff'),
       url('/assets/fonts/bankgothic-md-bt-medium-webfont.ttf') format('truetype'),
       url('/assets/fonts/bankgothic-md-bt-medium-webfont.svg#webfontc0f9s3aJ') format('svg');
  font-weight: bold;
  font-style: normal;
}
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursorPointer {
  cursor:pointer;
}

.displayNone {
  display:none;
}